p {
    color: #62676E;
}

.main_vertical_layout {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0 15rem;
}

.main_horizontal_layout {
    display: flex;
    flex-direction: row;
    justify-content: start;
    /*margin: 0 15rem;*/
}

.main_grid {
    margin: 3rem 0 0;
}

.main_grid div {
    margin-top: 3rem;
    height: 10rem;
    text-align: center;
}

.main_grid .main_horizontal_layout {
    width: 100%
}

.main_title {
    margin-top: 6rem;
    margin-bottom: 1rem;
    font-size: 3rem;
}

.main_description {
    font-size: 1.2rem;
}




