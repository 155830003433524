p {
    color: #62676E;
}

.page_header {
    margin-left: 15rem;
    position: relative;
}

.top_bar {
    width: 100%;
    height: 4rem;
    background-color: #282B32;
    position: absolute;
    top: 0;
}