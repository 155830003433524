.title {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    color: black;
}

.explanation {
    font-size: 1rem;
}

.border {
    border: 2px outset #666464;
    box-shadow: 3px 3px 5px gray;
    border-radius: 5px;
    padding: 0 1rem;
    margin-top: 1rem;
}